import {ModelImageMap} from '../typings/general';
import React from 'react';


export const ModelImageMapContext = React.createContext<ModelImageMap | undefined>(
         undefined
       );

export function useModelImageMap(): ModelImageMap {
         const imageMap = React.useContext(ModelImageMapContext);
         if (!imageMap) {
           console.log('No defined imageMap structure');
           return {};
         }
         return imageMap;
       }
