import React from 'react';

import { useFeatureAppConfig } from './services/use-feature-app-config';
import InlineFormMode from './pages/inline-form-mode';
import ModalFormMode from './pages/modal-form-mode';
import isBrowser from 'is-browser';
import {
  getModelStructure,
  getModelImage,
  getModelName,
  getAppMainConfiguration,
} from './utils/general';
import { ModelStructureContext } from './services/use-model-structure';
import { useModelConfig } from './services/use-model-config';
import { ModelImageMapContext } from './services/use-model-image-map';
import { LoaderSpinner } from '@ngw6/us-components';
import { ModelDataContext } from './services/use-model-data';
import { StyledAppContainer } from './styles';
import InlineImageMode from './pages/inline-image-mode';
import { useFeatureAppContent } from './services/use-feature-app-content';
import { FeatureAppMainConfig } from './services/use-feature-app-main-config';

export interface AppProps {
  readonly baseUrl?: string;
}

export function App(): JSX.Element | null {
  let featureAppConfig = useFeatureAppConfig();
  let featureAppContent = useFeatureAppContent();
  const appMainConfiguration = getAppMainConfiguration(
    featureAppConfig,
    featureAppContent,
  );
  const { mode, carlineId, formConfiguration } = appMainConfiguration;
  let modelStructure;
  let modelImage;
  let modelName;

  if (isBrowser) {
    try {
      const modelConfig = useModelConfig();
      modelStructure = getModelStructure(modelConfig);
      modelImage = getModelImage(
        featureAppConfig?.faHostServerUrl,
        modelConfig,
      );
      modelName = getModelName(modelConfig, carlineId);
      if (modelName && modelName.name) {
        modelName = { ...modelName, modelImage: modelImage[modelName.name] };
      }
    } catch (e) {
      console.log('ModelConfig Error ', e);
      return <LoaderSpinner label={'There was an error loading application'} />;
    }
  }

  if (!modelStructure || !modelImage) return <LoaderSpinner />;

  return (
    <StyledAppContainer
      featureAppSize={formConfiguration?.featureAppSize}
      className="form-app-container"
    >
      <FeatureAppMainConfig.Provider value={appMainConfiguration}>
        <ModelStructureContext.Provider value={modelStructure}>
          <ModelImageMapContext.Provider value={modelImage}>
            <ModelDataContext.Provider value={modelName}>
              {mode === 'inline' ? (
                <InlineFormMode />
              ) : mode === 'inline-image' ? (
                <InlineImageMode />
              ) : (
                <ModalFormMode mode={mode} />
              )}
            </ModelDataContext.Provider>
          </ModelImageMapContext.Provider>
        </ModelStructureContext.Provider>
      </FeatureAppMainConfig.Provider>
    </StyledAppContainer>
  );
}
