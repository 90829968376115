import { ModelData } from '../typings/general';
import React from 'react';

export const ModelDataContext = React.createContext<ModelData | undefined>(
  undefined,
);

export function useModelData(): ModelData | undefined {
  const modelData = React.useContext(ModelDataContext);
  if (!modelData) {
    return undefined;
  }
  return modelData;
}
