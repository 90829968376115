import React from 'react';
import Form from '../../components/form';
import { StyledInlineContainer } from './styled';
import FormikCustom from '../../components/formik-custom';
import { FormState, ModelImage } from '../../typings/general';
import { AppConstants } from '../../utils/app-constants';
import Banner from '../../components/banner';
import { ProcessingView } from '@ngw6/us-components';
import { useTrackingManager } from '../../services/use-tracking-manager';
import { onVWBasicLayerloadEvent } from '../../utils/tracking-helpers';
import _ from 'lodash';
import { useModelData } from '../../services/use-model-data';
import { useFeatureAppMainConfig } from '../../services/use-feature-app-main-config';
import { useFeatureAppConfig } from '../../services/use-feature-app-config';

const ModalFormMode = () => {
  const { faHostServerUrl = '' } = useFeatureAppConfig();
  const [formState, setFormState] = React.useState<FormState>('filling');
  const [modelImage, setModelImage] = React.useState<ModelImage>({
    url: faHostServerUrl + AppConstants.Ngw6Logo480x216,
    alt: 'Error image',
  });

  // Tracking Section
  const trackingManager = useTrackingManager();
  let {
    mode,
    fieldsConfiguration,
    formConfiguration,
    inputFields,
  } = useFeatureAppMainConfig();
  const modelData = useModelData();

  React.useEffect(() => {
    onVWBasicLayerloadEvent(
      trackingManager,
      true,
      formConfiguration,
      modelData,
    );
  }, []);

  const showBanner =
    mode !== 'inline' || (mode === 'inline' && !formConfiguration?.hideBanner);
  return (
    <div id="formInline">
      {showBanner ? <Banner isInline /> : <></>}
      <StyledInlineContainer background={formConfiguration?.formBackground}>
        {formState === 'submitting' && <ProcessingView active={true} />}
        <FormikCustom
          setFormState={setFormState}
          setModelImage={setModelImage}
          isInline
        >
          <Form
            formState={formState}
            modelImage={modelImage}
            setFormState={setFormState}
            isFormInline
            withPadding={showBanner}
            fieldsConfiguration={fieldsConfiguration}
            formConfiguration={formConfiguration}
            fieldsFormat={inputFields}
          />
        </FormikCustom>
      </StyledInlineContainer>
    </div>
  );
};

export default ModalFormMode;
