import React from 'react';
import { useFeatureServices } from './use-feature-services';
import _ from 'lodash';
import { FormConfiguration } from '../typings/configuration';

export interface FeatureAppTrackingManager {
  trackFormsGlobalEvent(
    action: string,
    model: any,
    trackEvent?: FeatureAppTrackingEvent,
  ): void;
}

export interface TrackingEvent {
  readonly [key: string]: object;
}

type Brand = 'passenger' | 'commercial';

export interface FeatureAppTrackingEvent {
  eventInfo?: {
    contentId?: string;
    pageCategory?: string;
    sectionName?: string;
    templateType?: string;
    brand?: Brand;
    contentLabels?: [];
  };

  environment?: {
    featureAppIdAppVersion?: string;
    region?: string;
    featureAppId?: string;
  };

  user?: {
    loginStatus?: boolean;
    locationZip?: string;
    locationLatitude?: string;
    locationLongitude?: string;
    loginId?: string;
  };
  content?: {
    Asset?: [];
    Type?: string;
    Name?: string;
    ItemCount?: number;
    ItemPosition?: string;
  };
  personalization?: {
    status?: boolean;
    moduleId?: string;
    personalizable?: boolean;
    placeholderId?: string;
    contentId?: string;
    activityId?: string;
    activityName?: string;
    experienceId?: string;
    experienceName?: string;
    offerId?: string;
    offerName?: string;
  };

  configuration?: {
    BodyTypeName?: string;
    CarlineId?: string;
    CarlineName?: string;
    CarlineIsRecommendation?: string;
    ModelName?: string;
    SalesgroupId?: string;
    SalesgroupName?: string;
    SalesgroupIsRecommendation?: string;
    EquipmentlineName?: string;
    EquipmentlineIsRecommendation?: string;
    ModelId?: string;
    ModelTrimFeatures?: string;
    ModelYear?: string;
    WheelsAdditionalExternalRollingNoise?: string;
    PriceType?: string;
    PriceTypeParameter?: [];
    PriceRateCurrency?: string;
    PriceRateType?: string;
    PriceRateBasic?: string;
    PriceRateAdditional?: string;
    PriceRateTotal?: string;
    PriceTotalCurrency?: string;
    PriceTotalBasic?: string;
    PriceTotalAdditional?: string;
    PriceTotalTotal?: string;
    PriceCampaignID?: string;
    PriceCampaignDescription?: string;
    PriceCampaignValue?: string;
    vehicleidentificationnumber?: string;
  };

  partner?: {
    informationBnr?: string;
    informationName?: string;
    informationDepartment?: string;
    informationZIP?: string;
    informationDistance?: number;
    SearchNumberOfResults?: number;
    onlinePaymentOption?: boolean;
    appointmentSystemName?: string;
    appointmentSystemUtilization?: boolean;
  };

  form?: {
    url?: string;
    name?: string;
    FormType?: string;
    FormTypeMultiple?: [];
    FormName?: string;
    NumberOfAppointments?: number;
    Appointments?: [];
    FormFieldName?: string;
    TextFieldValue?: [];
    PrefContactChannels?: string;
    FormFields?: string[];
    ErrorFormFields?: [];
    ErrorAdressValidationFields?: [];
    FormVersion?: string;
    MarketingConsent?: [];
    OpportunityUID?: string;
    orderId?: string;
    LeadID?: string;
    NewsletterType?: string[] | string;
    FormStart?: boolean;
    CycleID?: string;
    Questionnaire?: [];
  };

  process?: {
    Name?: string;
    StepName?: string;
    StepNumber?: string;
    Components?: [];
  };

  link?: {
    url?: string;
    name?: string;
    newTab?: boolean;
  };

  error?: {
    code?: string;
    message?: string;
    referringUrl?: string;
  };

  video?: {
    playerName?: string;
    title?: string;
    id?: string;
    platform?: string;
    url?: string;
    milestone?: number;
    timeplayed?: number;
    length?: number;
  };

  filter?: {
    results?: [];
    numberOfResults?: number;
    filterlist?: any;
    sortOrder?: [];
    type?: string;
    name?: string;
    values?: [];
    lowerbound?: string;
    upperbound?: string;
  };
  ownership?: {
    vehicle?: any;
  };
}

const defaultTrackingEvent: FeatureAppTrackingEvent = {
  eventInfo: {
    brand: 'passenger',
  },

  environment: {
    featureAppIdAppVersion: process.env.APPVERSION,
  },

  user: undefined,

  content: undefined,

  personalization: undefined,

  configuration: undefined,

  partner: undefined,

  form: undefined,

  process: undefined,

  link: undefined,

  error: undefined,

  video: undefined,

  filter: undefined,

  ownership: undefined,
};

export const getDefaultTrackingEvent = () => _.cloneDeep(defaultTrackingEvent);

export function buildTrackingEvent(
  model: FormConfiguration,
  cusTrackEvent?: FeatureAppTrackingEvent,
): FeatureAppTrackingEvent {
  let trackingEvent: FeatureAppTrackingEvent;

  if (cusTrackEvent) {
    trackingEvent = _.cloneDeep(cusTrackEvent);
  } else {
    trackingEvent = _.cloneDeep(defaultTrackingEvent);
  }
  if (model?.title) {
    trackingEvent.eventInfo = {
      ...trackingEvent.eventInfo,
      sectionName: model.title,
    };
  }

  // if (model.vin) {
  //   trackingEvent.configuration!.vehicleidentificationnumber = model.vin;
  // }

  // if (model.FormStart) {
  //   trackingEvent.form!.FormStart = model.FormStart;
  // }

  // if (model.FormType) {
  //   trackingEvent.form!.FormType = model.FormType;
  // }

  // if (model.FormVersion) {
  //   trackingEvent.form!.FormVersion = model.FormVersion;
  // }

  // if (model.FormName) {
  //   trackingEvent.form!.FormName = model.FormName;
  // }

  // if (model.url) {
  //   trackingEvent.link!.url = model.url;
  // }

  // if (model.name) {
  //   trackingEvent.link!.name = model.name;
  // }

  // if (model.newTab) {
  //   trackingEvent.link!.newTab = model.newTab;
  // }

  // if (model.code) {
  //   trackingEvent.error!.code = model.code;
  // }

  // if (model.message) {
  //   trackingEvent.error!.message = model.message;
  // }

  // if (model.linkUrl) {
  //   trackingEvent.link!.url = model.linkUrl;
  // }

  // if (model.linkName) {
  //   trackingEvent.link!.name = model.linkName;
  // }

  return trackingEvent;
}

export function getTrackEventClone(): FeatureAppTrackingEvent {
  return _.cloneDeep(defaultTrackingEvent);
}

export function useTrackingManager(): FeatureAppTrackingManager {
  const { tracking: trackingManager } = useFeatureServices();

  return React.useMemo((): FeatureAppTrackingManager => {
    function track(action: string, event: FeatureAppTrackingEvent): void {
      if (trackingManager) {
        trackingManager.track(action, (event as unknown) as TrackingEvent);
      }
    }

    return {
      trackFormsGlobalEvent(
        event: string,
        model: any,
        trackEvent?: FeatureAppTrackingEvent,
      ): void {
        track(event, buildTrackingEvent(model, trackEvent));
      },
    };
  }, [trackingManager]);
}
