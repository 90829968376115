import { Breakpoints, styled } from '@ngw6/us-components';

interface InlineProps {
  inline?: boolean;
}
export const StateWrapper = styled.div<InlineProps>`
  display: flex;
  align-items: ${({ inline }: InlineProps) => (inline ? `top` : `center`)};
  justify-content: center;
`;

export const ContentWrapper = styled.div<InlineProps>`
  ${({ inline }: InlineProps) => `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  padding-top: ${inline ? `0` : `50px`};
  width: 100vw;

  @media (min-width: ${Breakpoints.b560}px) {
    justify-content: center;
    height: auto;
    padding-bottom: 250px;
    padding-top: 250px;
    ${inline && `padding: 0`};
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    justify-content: center;
    height: auto;
    padding-bottom: 100px;
    padding-top: 100px;
     ${inline && `padding: 0`};
  }
  `}
`;
