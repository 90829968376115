import {History} from 'history';
import React from 'react';

export const HistoryContext = React.createContext<History | undefined>(
  undefined
);

export function useHistory(): History {
  const history = React.useContext(HistoryContext);

  if (!history) {
    throw new Error('No history context value was provided.');
  }

  return history;
}
