import { object, optional, string, array, enums } from 'superstruct';
import { FeatureAppMode } from '..';
import { FailedConfiguration } from '../components/form-state-views/failed-view';
import { SuccessConfiguration } from '../components/form-state-views/success-view';

export type formFieldType =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'preferredContactMethod'
  | 'comments'
  | 'modelName'
  | 'trim'
  | 'zip';

export const formFieldsTypes = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'preferredContactMethod',
  'comments',
  'modelName',
  'trim',
  'zip',
];

export const formModesEnum = [
  'inline',
  'default',
  'modal',
  'button',
  'inline-image',
];

export const booleanEnum = ['true', 'false'];

/**
 * Style Config
 */
export interface InlineImageModeStyle {
  background?: string;
  formBackground?: string;
  fieldsStructure?: string;
  structureColumns?: string;
  hideModelDropdown?: string;
  hideBanner?: string;
  messageModal?: string;
  featureAppSize?: string;
  CSS?: string;
}
// SCHEMA DEFINITION
export const styleSchemaObject = object({
  background: optional(string()),
  formBackground: optional(string()),
  fieldsStructure: optional(string()),
  structureColumns: optional(string()),
  hideModelDropdown: optional(enums(booleanEnum)),
  hideBanner: optional(enums(booleanEnum)),
  featureAppSize: optional(string()),
  messageModal: optional(string()),
  CSS: optional(string()),
});

/**
 * Image Config
 */
export interface InlineImageModeImageConfig {
  src: string;
  alt?: string;
  errorImageSrc?: string;
  disclaimer?: string;
}
// SCHEMA DEFINITION
export const imageSchemaObject = object({
  src: string(),
  alt: optional(string()),
  errorImageSrc: optional(string()),
  disclaimer: optional(string()),
});

/**
 * Form Fields
 */
export interface FormFields {
  name?: string;
  label?: string;
  formType?: formFieldType;
}
// SCHEMA DEFINITION
export const fieldSchemaObject = object({
  name: optional(string()),
  label: optional(string()),
  formType: optional(enums(formFieldsTypes)),
});

/**
 * Text Content
 */
export interface InlineImageModeContent {
  formName?: string;
  title?: string;
  CTAlabel?: string;
  modalTitle?: string;
  description?: string;
  legalDisclaimer?: string;
  submitCTA?: string;
  messageModal?: string;
}
// SCHEMA DEFINITION
export const contentSchemaObject = object({
  formName: optional(string()),
  modalTitle: optional(string()),
  description: optional(string()),
  title: optional(string()),
  CTAlabel: optional(string()),
  legalDisclaimer: optional(string()),
  submitCTA: optional(string()),
  messageModal: optional(string()),
});

/**
 * Tracking Config
 */
export interface TrackingConfiguration {
  disableTracking?: string;
  isNewsLetter?: string;
  modelName?: string;
  formTrackingType?: string;
  successRequestCountryCode?: string;
  successRequestLeadId?: string;
  formTrackingId?: string;
}
// SCHEMA DEFINITION
export const trackingSchemaObject = object({
  disableTracking: optional(enums(booleanEnum)),
  isNewsLetter: optional(enums(booleanEnum)),
  modelName: optional(string()),
  formTrackingType: optional(string()),
  successRequestCountryCode: optional(string()),
  successRequestLeadId: optional(string()),
  formTrackingId: optional(string()),
});

/**
 * FeatureApp Configuration
 * Mode;
 */
export interface FeatureAppInlineModeImage {
  authoredMode?: FeatureAppMode;
  carlineId?: string;
}
// SCHEMA DEFINITION
export const featureAppSchemaObject = object({
  authoredMode: optional(enums(formModesEnum)),
  carlineId: optional(string()),
});

/**
 * Success Modal Configuration
 */
export interface InlineImageModeSuccessContent {
  headerLabel?: string;
  description?: string;
  buttonCtaLabel?: string;
  imageSrc?: string;
  imageAlt?: string;
  errorImageSrc?: string;
}
// SCHEMA DEFINITION
export const successSchemaObject = object({
  headerLabel: optional(string()),
  description: optional(string()),
  buttonCtaLabel: optional(string()),
  imageSrc: optional(string()),
  imageAlt: optional(string()),
  errorImageSrc: optional(string()),
});

/**
 * Failed Modal Configuration
 */
export interface FormConfiguration {
  requestType?: string;
  paramsType?: string;
  implicitZipCode?: string;
}
const requestTypeEnum = ['leads', 'handraiser'];
const paramsTypeEnum = ['queryParamsJson', 'json'];
// SCHEMA DEFINITION
export const formConfigSchemaObject = object({
  requestType: optional(enums(requestTypeEnum)),
  paramsType: optional(enums(paramsTypeEnum)),
  implicitZipCode: optional(enums(booleanEnum)),
});

/**
 * Failed Modal Configuration
 */
export interface InlineImageModeFailedContent {
  headerLabel?: string;
  description?: string;
  buttonCtaLabel?: string;
}
// SCHEMA DEFINITION
export const failedSchemaObject = object({
  headerLabel: optional(string()),
  description: optional(string()),
  buttonCtaLabel: optional(string()),
});

export interface InlineImageFormSchema extends FormSchema {}

/**
 * Main Structure Object from content
 */
export interface FormSchema {
  mode?: FeatureAppInlineModeImage[];
  style?: InlineImageModeStyle[];
  image?: InlineImageModeImageConfig[];
  content?: InlineImageModeContent[];
  successMessageContent?: SuccessConfiguration[];
  failedMessageContent?: FailedConfiguration[];
  fields?: FormFields[];
  metaData?: string;
  trackingConfiguration?: TrackingConfiguration[];
  formConfiguration?: FormConfiguration[];
}

export const schemaObject = {
  mode: optional(array(featureAppSchemaObject)),
  style: optional(array(styleSchemaObject)),
  image: optional(array(imageSchemaObject)),
  content: optional(array(contentSchemaObject)),
  successMessageContent: optional(array(successSchemaObject)),
  failedMessageContent: optional(array(failedSchemaObject)),
  fields: optional(array(fieldSchemaObject)),
  metaData: optional(string()),
  trackingConfiguration: optional(array(trackingSchemaObject)),
  formConfiguration: optional(array(formConfigSchemaObject)),
};

export const FormContentSchema = object(schemaObject);
