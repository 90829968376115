import { FormikContextType, useFormikContext } from 'formik';
import React from 'react';
import {
  DisableFocusWrapper,
  Button,
  ButtonAppearance,
  ButtonType,
  TextColor,
  TextAppearance,
  getInputTextElement,
  getRadioButtonGroupElement,
  getTextAreaInput,
  LegalFormDisclaimer,
  TextCustom,
  TextSize,
  TextLineHeight,
} from '@ngw6/us-components';
import { FieldsConfiguration } from '../../typings/configuration';
import {
  StyledFormTitle,
  StyledLegalDisclaimer,
  StyledForm,
  StyledFormsFieldsContainer,
  StyledModelWrapper,
  StyledFirstNameWrapper,
  StyledLastNameWrapper,
  StyledEmailWrapper,
  StyledPhoneWrapper,
  StyledContactMethodWrapper,
  StyledCommentsWrapper,
  StyledZipWrapper,
  StyledSubmitButton,
} from './styled';
import { ModelStructure } from '../../typings/general';
import { ConfigFieldsModel, defaultFields } from '../../config/formFields';
import { SelectGroup } from '../select-group';

export interface DynamicFormProps {
  children?: React.ReactChild;
  requiredfields: FieldsConfiguration;
  formStructure?: string;
  columns?: number;
  modelsMap?: ModelStructure;
  submitLabel: string;
  legalDisclaimer: string;
  formName?: string;
  carlineName?: string;
  hideDropdown?: boolean;
  fieldsConfig?: ConfigFieldsModel;
}

export const DynamicForm: React.FC<DynamicFormProps> = ({
  requiredfields,
  formStructure,
  children,
  modelsMap,
  columns,
  submitLabel,
  formName,
  legalDisclaimer,
  carlineName,
  hideDropdown,
  fieldsConfig,
}): JSX.Element => {
  const props: FormikContextType<any> = useFormikContext();
  const { isSubmitting, handleSubmit, isValid } = props;
  const fields: any = fieldsConfig || defaultFields;

  const defaultStructure =
    '"model" "firstName" "lastName" "email" "phone" "contactMethod" "zip" "comments"';
  return (
    <>
      <StyledForm
        onSubmit={handleSubmit}
        name={formName}
        id={formName}
        className="dynamic-form"
      >
        <StyledFormTitle className="form-title">{children}</StyledFormTitle>

        <StyledFormsFieldsContainer
          structure={formStructure || defaultStructure}
          columns={columns || 1}
          className="form-fields"
        >
          {typeof requiredfields['modelName'] !== 'undefined' && !hideDropdown && (
            <StyledModelWrapper className="form-field-model-name" tabIndex="0">
              <SelectGroup
                masterSelect={fields.modelName}
                slaveSelect={fields.trim}
                dropdownOption={modelsMap || {}}
                formikProps={props}
                disableMaster={carlineName ? true : false}
                selectedMaster={
                  carlineName && modelsMap && modelsMap[carlineName]
                    ? { value: carlineName, label: carlineName }
                    : undefined
                }
              />
            </StyledModelWrapper>
          )}
          {typeof requiredfields['firstName'] !== 'undefined' && (
            <StyledFirstNameWrapper
              className="form-field-first-name"
              tabIndex="0"
            >
              {getInputTextElement(fields.firstName, props)}
            </StyledFirstNameWrapper>
          )}

          {typeof requiredfields['lastName'] !== 'undefined' && (
            <StyledLastNameWrapper
              className="form-field-last-name"
              tabIndex="0"
            >
              {getInputTextElement(fields.lastName, props)}
            </StyledLastNameWrapper>
          )}
          {typeof requiredfields['email'] !== 'undefined' && (
            <StyledEmailWrapper className="form-field-email" tabIndex="0">
              {getInputTextElement(
                fields.email,
                props,
                typeof props.values.preferredContactMethod !== 'undefined' &&
                  ![fields.email.name, 'either'].includes(
                    props.values.preferredContactMethod,
                  ),
              )}
            </StyledEmailWrapper>
          )}
          {typeof requiredfields['phone'] !== 'undefined' && (
            <StyledPhoneWrapper className="form-field-phone" tabIndex="0">
              {getInputTextElement(
                fields.phone,
                props,
                typeof props.values.preferredContactMethod !== 'undefined' &&
                  ![fields.phone.name, 'either'].includes(
                    props.values.preferredContactMethod,
                  ),
              )}
            </StyledPhoneWrapper>
          )}

          {typeof requiredfields['preferredContactMethod'] !== 'undefined' && (
            <StyledContactMethodWrapper
              className="form-field-contact-method"
              tabIndex="0"
            >
              {getRadioButtonGroupElement(fields.preferredContactMethod, props)}
            </StyledContactMethodWrapper>
          )}
          {typeof requiredfields['comments'] !== 'undefined' && (
            <StyledCommentsWrapper className="form-field-comments" tabIndex="0">
              {getTextAreaInput(fields.comments, props)}
            </StyledCommentsWrapper>
          )}
          {typeof requiredfields['zip'] !== 'undefined' && (
            <StyledZipWrapper className="form-field-zip" tabIndex="0">
              {getInputTextElement(fields.zip, props)}
            </StyledZipWrapper>
          )}
        </StyledFormsFieldsContainer>
        <StyledLegalDisclaimer className="form-legal-disclaimer">
          <LegalFormDisclaimer disclaimer={legalDisclaimer} />
        </StyledLegalDisclaimer>
        <StyledSubmitButton className="form-submit-button" tabIndex="0">
          <DisableFocusWrapper>
            <Button
              appearance={ButtonAppearance.Primary}
              type={ButtonType.Submit}
              disabled={isSubmitting || !isValid}
              stretchContent
            >
              <TextCustom
                appearance={TextAppearance.copy100}
                style={{
                  color: TextColor.inherit,
                  fontSize: TextSize.TextSize14,
                  lineHeight: TextLineHeight.TextLineHeight24,
                }}
              >
                {submitLabel || 'Submit'}
              </TextCustom>
            </Button>
          </DisableFocusWrapper>
        </StyledSubmitButton>
      </StyledForm>
    </>
  );
};
