import 'isomorphic-fetch';
import {
  getInvalidStates,
  getSuccessStates,
  FormResponseState,
} from './helpers/response-states';
import { stringMap } from '../../typings/general';

interface RequestOptions {
  readonly faServicesUrl: string;
  readonly serviceConfigsServiceConfig?: string;
  readonly mockIds?: string;
  readonly mocksBaseUrl?: string;
  readonly userParams: stringMap;
  readonly requestType: string;
  readonly paramsType: string;
}

function createSubmitUrl(params: URLSearchParams, urlOrigin: string): string {
  return `${urlOrigin}/s2f?${params.toString()}`;
}

export class SubmitFromError extends Error {
  public constructor(message: string, public readonly url?: string) {
    super(message);
  }
}

export async function submitForm({
  faServicesUrl,
  serviceConfigsServiceConfig,
  mockIds,
  mocksBaseUrl,
  userParams,
  requestType,
  paramsType,
}: RequestOptions): Promise<FormResponseState> {
  const params = new URLSearchParams();

  if (serviceConfigsServiceConfig) {
    params.set('serviceConfigsServiceConfig', serviceConfigsServiceConfig);
  }
  if (mockIds) {
    params.set('mockIds', mockIds);
  }

  if (mocksBaseUrl) {
    params.set('mocksBaseUrl', mocksBaseUrl);
  }

  if (paramsType === 'json') {
    params.set('json', JSON.stringify(userParams));
  } else {
    params.set('queryParamsJson', JSON.stringify(userParams));
  }
  params.set('s2fFormPath', requestType);

  const response = await fetch(createSubmitUrl(params, faServicesUrl));
  const result = await response.json();
  if (!result || !result.leadId) {
    console.warn(`Form submit failed, did not get a lead ID`);
    return {
      data: {},
      ...getInvalidStates(),
      submitted: true,
    };
  }
  return {
    data: result,
    ...getSuccessStates(),
    submitted: true,
  };
}
