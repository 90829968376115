import {
  createTagOverride,
  TextCustom,
  TextAppearance,
  TextWeight,
  TextTag,
  TextSize,
  TextLineHeight,
} from '@ngw6/us-components';
import { ModelImage } from '../../typings/general';
import { AppConstants } from '../../utils/app-constants';
import { SuccessConfiguration } from '../form-state-views/success-view';

export const titleOverridesTags: any = {
  span: createTagOverride(TextCustom, {
    appearance: TextAppearance.headline300,
    style: {
      fontWeight: TextWeight.bold,
      fontSize: TextSize.TextSize28,
      lineHeight: TextLineHeight.TextLineHeight32,
    },
    tag: TextTag.span,
  }),
  div: createTagOverride(TextCustom, {
    appearance: TextAppearance.headline300,
    style: {
      fontWeight: TextWeight.bold,
      fontSize: TextSize.TextSize28,
      lineHeight: TextLineHeight.TextLineHeight32,
    },
    tag: TextTag.div,
  }),
  p: createTagOverride(TextCustom, {
    appearance: TextAppearance.headline300,
    style: {
      fontWeight: TextWeight.regular,
      fontSize: TextSize.TextSize28,
      lineHeight: TextLineHeight.TextLineHeight32,
    },
    tag: TextTag.p,
  }),
  strong: createTagOverride(TextCustom, {
    appearance: TextAppearance.headline300,
    style: {
      fontWeight: TextWeight.bold,
      fontSize: TextSize.TextSize28,
      lineHeight: TextLineHeight.TextLineHeight32,
    },
    tag: TextTag.strong,
  }),
};

export const getSuccessFormContent = (
  modelImage: ModelImage,
  faHostServerUrl: string,
  successContent?: SuccessConfiguration,
) => {
  const { image: customImage } = successContent || {};
  return {
    ...successContent,
    image: {
      src:
        customImage?.src ||
        modelImage.url ||
        faHostServerUrl + AppConstants.Ngw6Logo960x432 ||
        '',
      alt: customImage?.alt || modelImage?.alt || '',
      srcPlaceholder: AppConstants.PlaceHolder960x432 as string,
      srcErrorImage: faHostServerUrl + AppConstants.Ngw6Logo960x432,
    },
  };
};
