import {Logger} from '@feature-hub/core';
// import {useFeatureServices} from './use-feature-services';

export function useLogger(): Logger {
  // TODO: Use the logger feature service when the bugfix for the consumer
  // logger in the CMS has been released.
  // See https://git.sinnerschrader.com/vwa/vwacms18/merge_requests/1635

  // const {['s2:logger']: logger} = useFeatureServices();

  return console;
}
