import {
  CustomImage,
  ModalContainer,
  ProcessingView,
  Richtext,
} from '@ngw6/us-components';
import _ from 'lodash';
import React from 'react';
import AuthoredForm from '../../components/authored-form';
import { FailedView } from '../../components/form-state-views/failed-view';
import { SuccessView } from '../../components/form-state-views/success-view';
import FormikCustom from '../../components/formik-custom';
import ImageDisclaimer from '../../components/image-disclaimer';
import { useFeatureAppConfig } from '../../services/use-feature-app-config';
import { useFeatureAppContent } from '../../services/use-feature-app-content';
import { useFeatureAppMainConfig } from '../../services/use-feature-app-main-config';
import { useModelData } from '../../services/use-model-data';
import { useTrackingManager } from '../../services/use-tracking-manager';
import { FormState, ModelImage } from '../../typings/general';
import { AppConstants } from '../../utils/app-constants';
import {
  onHandleLoadTracking,
  onVWBasicLayerloadEvent,
} from '../../utils/tracking-helpers';
import { getFormImageConfiguration, titleOverridesTags } from './helpers';
import {
  StyledContentImage,
  StyledContentTitle,
  StyledInlineImageFormContainer,
  StyledInlineImageWrapper,
  StyledModalContainer,
  StyledWidthContainer,
} from './styles';

interface InlineImageModeProps {}
const InlineImageMode: React.FC<InlineImageModeProps> = () => {
  const { faHostServerUrl = '' } = useFeatureAppConfig();
  const [formState, setFormState] = React.useState<FormState>('filling');
  const [modalActive, setModalActive] = React.useState<boolean>(false);
  const [modelImage, setModelImage] = React.useState<ModelImage>({
    url: faHostServerUrl + AppConstants.Ngw6Logo480x216,
    alt: 'Error image',
  });

  // Tracking Section
  const trackingManager = useTrackingManager();
  const {
    formConfiguration,
    fieldsConfiguration: formRequestData,
    inputFields: fieldsConfig,
  } = useFeatureAppMainConfig();
  const featureAppContent = useFeatureAppContent();
  const formCustomImage = getFormImageConfiguration(
    faHostServerUrl,
    featureAppContent.image,
  );
  const modelData = useModelData();

  /**
   * Changing modal state
   */
  const onCloseStateHandle = () => {
    setFormState('filling');
    setModalActive(false);
  };

  /**
   * Success modal close handle
   */
  const onCloseHandle = () => {
    onCloseStateHandle();
    onHandleLoadTracking(
      'close',
      trackingManager,
      formConfiguration,
      modelData,
    );
  };

  React.useEffect(() => {
    onVWBasicLayerloadEvent(
      trackingManager,
      true,
      formConfiguration,
      modelData,
    );
  }, []);

  React.useEffect(() => {
    if (
      ['success', 'failed'].includes(formState) &&
      formConfiguration &&
      formConfiguration.messageModal
    ) {
      setModalActive(true);
    }
  }, [formState]);

  return (
    <StyledWidthContainer
      background={formConfiguration?.background}
      CSS={formConfiguration.CSS}
      className="form-inline-width-wrapper"
    >
      <StyledInlineImageWrapper id="formInlineImage">
        <StyledContentTitle className="content-container-title">
          <Richtext
            markdown={formConfiguration.title || '${formConfiguration.title}'}
            overrides={titleOverridesTags}
            dangerouslyParseRawHTML
          />
        </StyledContentTitle>
        <StyledContentImage className="content-image">
          <CustomImage {...formCustomImage} />
          <ImageDisclaimer description={formCustomImage.description} />
        </StyledContentImage>
        <StyledInlineImageFormContainer className="form-inline-image-form-container">
          {formState === 'submitting' && <ProcessingView active={true} />}
          <FormikCustom
            setFormState={setFormState}
            setModelImage={setModelImage}
            fieldsContentConfig={formRequestData}
            isInline
          >
            <AuthoredForm
              formState={formState}
              modelImage={modelImage}
              setFormState={setFormState}
              fieldsConfiguration={formRequestData}
              formConfiguration={formConfiguration}
              fieldsFormat={fieldsConfig}
              isFormInline
            />
          </FormikCustom>

          {modalActive ? (
            <StyledModalContainer className="form-message-modal">
              <ModalContainer
                active={modalActive}
                onCloseHandler={onCloseHandle}
              >
                {formState === 'success' ? (
                  <SuccessView
                    okHandler={onCloseHandle}
                    contentConfiguration={
                      formConfiguration.successMessageContent
                    }
                  />
                ) : (
                  <FailedView
                    formName={
                      formConfiguration.formName ||
                      '${formConfiguration.formName}'
                    }
                    setFormStateHandler={onCloseStateHandle}
                    contentConfig={formConfiguration?.failedMessageContent}
                  />
                )}
              </ModalContainer>
            </StyledModalContainer>
          ) : (
            <></>
          )}
        </StyledInlineImageFormContainer>
      </StyledInlineImageWrapper>
    </StyledWidthContainer>
  );
};

export default InlineImageMode;
