import React from 'react';
import { defaultFields } from '../config/formFields';
import { AppConfiguration } from '../typings/general';
import { useFeatureAppEnvironment } from './use-feature-app-environment';

export const FeatureAppMainConfig = React.createContext<AppConfiguration | undefined>(
  undefined
);
export function useFeatureAppMainConfig(): AppConfiguration {
  const context = React.useContext(FeatureAppMainConfig);
  const { config } = useFeatureAppEnvironment();
  if (!context) {
    console.log('Warning: Feature app main config context was not defined');
  }

  return context || {...config, inputFields: defaultFields} as AppConfiguration;
}
