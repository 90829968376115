import React from 'react';
import { FormState, ModelImage } from '../../typings/general';
import { DynamicForm } from '../dynamic-form';
import _ from 'lodash';
import { useModelStructure } from '../../services/use-model-structure';
import {
  ModalFormWrapper,
  StyledModalFormWrapper,
  StyledSuccessView,
} from './styled';
import {
  SuccessConfiguration,
  SuccessView,
} from '../form-state-views/success-view';
import { FailedView } from '../form-state-views/failed-view';
import { useModelData } from '../../services/use-model-data';
import { getSuccessFormContent } from './helpers';
import {
  FieldsConfiguration,
  FormConfiguration,
} from '../../typings/configuration';
import { ConfigFieldsModel } from '../../config/formFields';
import { useFeatureAppConfig } from '../../services/use-feature-app-config';

interface AuthoredFormProps {
  formState: FormState;
  setFormState: (state: FormState) => void;
  modelImage: ModelImage;
  onCloseHandle?: () => void;
  isFormInline?: boolean;
  withPadding?: boolean;
  fieldsConfiguration: FieldsConfiguration;
  formConfiguration: FormConfiguration;
  fieldsFormat: ConfigFieldsModel;
}
const AuthoredForm: React.FC<AuthoredFormProps> = props => {
  let {
    formState,
    setFormState,
    modelImage,
    isFormInline,
    onCloseHandle,
    withPadding,
    fieldsConfiguration,
    formConfiguration,
    fieldsFormat,
  } = props;
  const { faHostServerUrl = '' } = useFeatureAppConfig();
  const modelStructure = useModelStructure();
  const modelData = useModelData();

  const onCloseHandleForm = () => {
    onCloseHandle && onCloseHandle();
  };
  if (modelData?.name && typeof fieldsConfiguration.modelName !== 'undefined') {
    fieldsConfiguration = {
      ...fieldsConfiguration,
      modelName: modelData?.name || fieldsConfiguration?.modelName,
    };
  }
  const successViewContentConfig: SuccessConfiguration = getSuccessFormContent(
    modelImage,
    faHostServerUrl,
    formConfiguration?.successMessageContent,
  );
  return (
    <ModalFormWrapper withTopPadding={withPadding}>
      <StyledModalFormWrapper>
        {formState === 'filling' || formState === 'submitting' ? (
          <DynamicForm
            requiredfields={fieldsConfiguration}
            formStructure={formConfiguration.formStructure}
            modelsMap={modelStructure}
            columns={formConfiguration.structureColumns}
            submitLabel={formConfiguration.submitCTA}
            legalDisclaimer={formConfiguration.legalDisclaimer}
            formName={formConfiguration.formName}
            carlineName={modelData?.name}
            hideDropdown={formConfiguration.hideModelDropdown}
            fieldsConfig={fieldsFormat}
          />
        ) : formState === 'success' &&
          formConfiguration.messageModal !== 'true' ? (
          <StyledSuccessView showButton={isFormInline}>
            <SuccessView
              okHandler={onCloseHandleForm}
              inline={isFormInline}
              contentConfiguration={successViewContentConfig}
            />
          </StyledSuccessView>
        ) : formConfiguration.messageModal !== 'true' ? (
          <FailedView
            formName={
              formConfiguration.formName || '${formConfiguration.formName}'
            }
            setFormStateHandler={() => setFormState('filling')}
            inline={isFormInline}
            contentConfig={formConfiguration?.failedMessageContent}
          />
        ) : (
          <></>
        )}
      </StyledModalFormWrapper>
    </ModalFormWrapper>
  );
};

export default AuthoredForm;
