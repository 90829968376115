import isBrowser from 'is-browser';
import React from 'react';
import {useFeatureServices} from './use-feature-services';
import { ModelConfig } from '../typings/model-config';

type UniversalStateAction =
  | {readonly type: 'fetchingFeatureAppModel'}
  | {
      readonly type: 'fetchedFeatureAppModel';
      fetchedModelOverview: ModelConfig;
    }
  | {readonly type: 'failedToFetchFeatureAppModel'; error: Error};

type FeatureAppModelResult =
  | {status: 'loading'}
  | {status: 'loaded'; featureAppModel: ModelConfig}
  | {status: 'failed'; error: Error; ssr: boolean};

export interface UniversalState {
  readonly featureAppModelResult?: FeatureAppModelResult;
}

function universalStateReducer(
  state: UniversalState,
  action: UniversalStateAction
): UniversalState {
  switch (action.type) {
    case 'fetchingFeatureAppModel':
      return {...state, featureAppModelResult: {status: 'loading'}};

    case 'fetchedFeatureAppModel':
      return {
        ...state,
        featureAppModelResult: {
          status: 'loaded',
          featureAppModel: action.fetchedModelOverview
        }
      };

    case 'failedToFetchFeatureAppModel':
      return {
        ...state,
        featureAppModelResult: {
          status: 'failed',
          error: action.error,
          ssr: !isBrowser
        }
      };

    default:
      return state;
  }
}

export function useUniversalState(): [
  UniversalState,
  React.Dispatch<UniversalStateAction>
] {
  const {'feature-app-memory-store': memoryStore} = useFeatureServices();

  const [universalState, setUniversalState] = React.useState(
    memoryStore.getState() || {}
  );

  const dispatch: React.Dispatch<UniversalStateAction> = action => {
    const newUniversalState = universalStateReducer(universalState, action);

    // Change the state of the memory store to transfer it from one server-side
    // render pass to another.
    // memoryStore.setState(newUniversalState);

    // Set the react state to trigger a re-render on the client.
    setUniversalState(newUniversalState);
  };

  return [universalState, dispatch];
}
