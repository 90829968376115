import {
  ModelConfig,
  CarlineConfigModel,
  TrimConfigModel,
} from '../typings/model-config';
import _ from 'lodash';
import {
  ModelStructure,
  TrimImageMap,
  ModelImageMap,
  ModelData,
  AppConfiguration,
} from '../typings/general';
import { AppConstants } from './app-constants';
import isBrowser from 'is-browser';
import { FieldsConfiguration } from '../typings/configuration';
import { FeatureAppConfig, FeatureAppMode } from '..';
import { FeatureAppContent } from '../services/use-feature-app-content';
import { FormFields } from '../typings/inline-image-form';
import {
  ConfigFieldsModel,
  defaultFields,
  DEFAULT_FIELDS_DATA,
} from '../config/formFields';
import {
  defaultFieldsConfig,
  defaultFormConfiguration,
} from '../config/localConfig';

export const getModelStructure = (
  modelOverview?: ModelConfig,
): ModelStructure | undefined => {
  if (modelOverview) {
    let modelStructure: ModelStructure = {};
    const models: CarlineConfigModel[] = _.values(modelOverview);
    models.map((model: CarlineConfigModel) => {
      if (model.name) {
        const trims: string[] = [];
        model.modelTrims &&
          model.modelTrims?.map((trim: TrimConfigModel) => {
            trim.name && trims.push(trim.name);
          });
        modelStructure[model.name] = trims;
      }
    });
    return modelStructure;
  }
  return undefined;
};

export const getModelImage = (
  faHostServerUrl: string,
  modelOverview?: ModelConfig,
): ModelImageMap | undefined => {
  if (modelOverview) {
    let modelImageMap: ModelImageMap = {};
    const models: CarlineConfigModel[] = _.values(modelOverview);
    models.map((model: CarlineConfigModel) => {
      let trims: TrimImageMap = {};
      model.modelTrims &&
        model.modelTrims?.map((trim: TrimConfigModel) => {
          trims[trim.name || ''] = {
            url: trim.mediaImageUrl,
            alt: trim.name || '',
          };
        });
      modelImageMap[model.name || ''] = {
        url: model.carImage || faHostServerUrl + AppConstants.Ngw6Logo960x432,
        alt: model.carImageAlt || model.name || '',
        modelYear: model.modelYear,
      };
    });
    return modelImageMap;
  }
  return undefined;
};

export const getModelName = (
  modelOverview?: ModelConfig,
  carlineId?: string,
): ModelData | undefined => {
  if (modelOverview && carlineId && modelOverview[carlineId]) {
    return modelOverview[carlineId];
  }
  if (carlineId) return { carlineId };
  return undefined;
};

/**
 * Get model name where vehicle option is available
 * @param fieldsConfiguration
 */
export const getModelNameForHandraiser = (
  fieldsConfiguration: FieldsConfiguration,
) => {
  if (fieldsConfiguration?.vehicles) {
    try {
      const vehicleJson = JSON.parse(fieldsConfiguration.vehicles);
      if (vehicleJson.length) {
        return vehicleJson[0].modelName;
      }
    } catch (error) {
      console.log('Tagging Warning: ', error);
    }
  }
  if (fieldsConfiguration?.modelName) {
    return fieldsConfiguration.modelName;
  }
};

/**
 * Enabled or disabled the scroll functionality
 * @param disabled Scroll state
 */
export const disableScroll = (disabled: boolean) => {
  if (isBrowser) {
    disabled
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }
};

const mergeFields = (fieldDefault: any, field: any) => {
  return { ...fieldDefault, ...field, label: field.label };
};

/**
 * Getting visible fields for users
 * @param defaultConfig
 * @param fields
 * @returns
 */
export const getFieldsConfiguration = (
  defaultConfig: ConfigFieldsModel,
  fields?: FormFields[],
): ConfigFieldsModel => {
  if (!fields?.length) return defaultConfig;
  let fieldsInput: any = _.keyBy(fields, 'formType');
  if (typeof fieldsInput['email'] !== 'undefined') {
    fieldsInput = {
      ...fieldsInput,
      email: {
        ...fieldsInput.email,
        optionalLabel: `${fieldsInput.email.label} ${defaultConfig.email.optionalLabel}`,
      },
    };
  }
  if (typeof fieldsInput['phone'] !== 'undefined') {
    fieldsInput = {
      ...fieldsInput,
      phone: {
        ...fieldsInput.phone,
        optionalLabel: `${fieldsInput.phone.label} ${defaultConfig.phone.optionalLabel}`,
      },
    };
  }
  return _.mergeWith(defaultConfig, fieldsInput, mergeFields);
};

/**
 * Unsets z-index value for pagemain container
 */
export const unsetPagemainZindex = () => {
  // The class name is used due to lack of id
  let pagemainContainer = document.getElementsByClassName('pagemain')[0];
  if (pagemainContainer && pagemainContainer.parentElement)
    pagemainContainer.parentElement.style.zIndex = 'unset';
};

export const getContentFormConfiguration = (appContent: FeatureAppContent) => {
  let formConfig;
  if (appContent.formConfiguration?.length) {
    let implicitZipCode: boolean | undefined = undefined;
    if (appContent.formConfiguration[0].implicitZipCode) {
      implicitZipCode =
        appContent.formConfiguration[0].implicitZipCode.toLowerCase() ===
        'true';
    }
    formConfig = { ...appContent.formConfiguration[0], implicitZipCode };
  }
  if (appContent.content?.length) {
    let messageModal: boolean | undefined = undefined;
    if (appContent.content[0].messageModal) {
      messageModal =
        appContent.content[0].messageModal.toLowerCase() === 'true';
    }
    formConfig = { ...formConfig, ...appContent.content[0], messageModal };
  }
  if (appContent.style?.length) {
    let hideBanner: boolean | undefined = undefined;
    let hideModelDropdown: boolean | undefined = undefined;
    const structureColumns = appContent.style[0].structureColumns
      ? Number.parseInt(appContent.style[0].structureColumns)
      : undefined;

    if (appContent.style[0].hideBanner) {
      hideBanner = appContent.style[0].hideBanner.toLowerCase() === 'true';
    }
    if (appContent.style[0].hideModelDropdown) {
      hideModelDropdown =
        appContent.style[0].hideModelDropdown.toLowerCase() === 'true';
    }
    formConfig = {
      ...formConfig,
      ...appContent.style[0],
      structureColumns,
      hideBanner,
      hideModelDropdown,
    };
  }
  if (appContent.trackingConfiguration?.length) {
    let isNewsLetter: boolean | undefined = undefined;
    let disableTracking: boolean | undefined = undefined;
    if (appContent.trackingConfiguration[0].isNewsLetter) {
      isNewsLetter =
        appContent.trackingConfiguration[0].isNewsLetter.toLowerCase() ===
        'true';
    }
    if (appContent.trackingConfiguration[0].disableTracking) {
      disableTracking =
        appContent.trackingConfiguration[0].disableTracking.toLowerCase() ===
        'true';
    }
    formConfig = {
      ...formConfig,
      ...appContent.trackingConfiguration[0],
      isNewsLetter,
      disableTracking,
    };
  }
  if (appContent.successMessageContent?.length) {
    formConfig = {
      ...formConfig,
      successMessageContent: {
        ...appContent.successMessageContent[0],
        image: {
          src: appContent.successMessageContent[0].imageSrc || '',
          alt: appContent.successMessageContent[0].imageAlt || '',
          srcPlaceholder: AppConstants.PlaceHolder480x216,
          srcErrorImage: appContent.successMessageContent[0].errorImageSrc,
        },
      },
    };
  }
  if (appContent.failedMessageContent?.length) {
    formConfig = {
      ...formConfig,
      failedMessageContent: appContent.failedMessageContent[0],
    };
  }
  return formConfig;
};

const mergeRequestData = (main: any, field: any) => {
  return field || main;
};
/**
 * Getting form request data from metadata and requested fields
 */
export const getFormRequestData = (
  fields?: FormFields[],
  metaData?: string,
): any => {
  let fieldsInputs = {};
  try {
    let mainObject = metaData && JSON.parse(metaData);
    if (!fields || fields.length === 0) throw new Error('No fields configured');
    fieldsInputs = _.keyBy(fields, 'formType');
    fieldsInputs = _.mapValues(fieldsInputs, () => '');
    fieldsInputs = _.mergeWith(mainObject, fieldsInputs, mergeRequestData);
  } catch (error) {
    console.log('ERROR configuring form data:', (error as any).message);
  }
  return fieldsInputs && !_.isEmpty(fieldsInputs) ? fieldsInputs : undefined;
};

export const getAppMainConfiguration = (
  appConfig: FeatureAppConfig,
  appContent: FeatureAppContent,
): AppConfiguration => {
  let formConfiguration = {
    ...defaultFormConfiguration,
    ...appConfig.formConfiguration,
  };
  let carlineId = appConfig.carlineId;
  let mode = appConfig.mode;
  let fieldsConfiguration = appConfig.fieldsConfiguration || {
    ...defaultFieldsConfig,
  };
  let fieldsConfig: undefined | FormFields[] = [...DEFAULT_FIELDS_DATA];

  if (appContent && appConfig.hasContentConfig) {
    if (appContent.mode?.length) {
      carlineId = appContent.mode[0].carlineId
        ? appContent.mode[0].carlineId
        : carlineId;
      mode = appContent.mode[0].authoredMode
        ? (appContent.mode[0].authoredMode as FeatureAppMode)
        : mode;
    }
    if (appContent.metaData) {
      try {
        fieldsConfiguration = getFormRequestData(
          appContent.fields as FormFields[],
          appContent.metaData,
        );
      } catch (e) {
        console.log(
          'Error: App Authored Meta Data is wrong, just JSON string is allowed',
        );
      }
    }
    if (appContent.fields?.length) {
      fieldsConfig = appContent.fields as FormFields[];
    }
    const formContentConfiguration = getContentFormConfiguration(appContent);
    formConfiguration = { ...formConfiguration, ...formContentConfiguration };
  }
  const inputFields = getFieldsConfiguration(
    { ...defaultFields },
    fieldsConfig,
  );
  return {
    carlineId,
    mode,
    formConfiguration,
    fieldsConfiguration,
    fieldsConfig,
    inputFields,
  };
};
