import {
  createTagOverride,
  TextCustom,
  TextAppearance,
  Breakpoints,
  TextColor,
  TextAlignment,
  TextSize,
  TextLineHeight,
  TextWeight,
} from '@ngw6/us-components';

// Text style
export const titleOverrides = {
  span: createTagOverride(TextCustom, {
    appearance: TextAppearance.headline400,
    style: {
      [Breakpoints.default]: {
        color: TextColor.inherit,
        textAlign: TextAlignment.center,
        fontSize: TextSize.TextSize32,
        lineHeight: TextLineHeight.TextLineHeight36,
      },
      [Breakpoints.b960]: {
        color: TextColor.inherit,
        textAlign: TextAlignment.center,
        fontSize: TextSize.TextSize48,
        lineHeight: TextLineHeight.TextLineHeight56,
      },
      [Breakpoints.b1600]: {
        color: TextColor.inherit,
        textAlign: TextAlignment.center,
        fontSize: TextSize.TextSize60,
        lineHeight: TextLineHeight.TextLineHeight68,
      },
    },
  }),
  strong: createTagOverride(TextCustom, {
    appearance: TextAppearance.headline400,
    style: {
      [Breakpoints.default]: {
        fontWeight: TextWeight.bold,
        color: TextColor.inherit,
        textAlign: TextAlignment.center,
        fontSize: TextSize.TextSize32,
        lineHeight: TextLineHeight.TextLineHeight36,
      },
      [Breakpoints.b960]: {
        color: TextColor.inherit,
        fontWeight: TextWeight.bold,
        textAlign: TextAlignment.center,
        fontSize: TextSize.TextSize48,
        lineHeight: TextLineHeight.TextLineHeight56,
      },
      [Breakpoints.b1600]: {
        color: TextColor.inherit,
        fontWeight: TextWeight.bold,
        textAlign: TextAlignment.center,
        fontSize: TextSize.TextSize60,
        lineHeight: TextLineHeight.TextLineHeight68,
      },
    },
  }),
};
export const descriptionOverrides = {
  span: createTagOverride(TextCustom, {
    appearance: TextAppearance.label200,
    style: {
      color: TextColor.inherit,
      textAlign: TextAlignment.center,
      lineHeight: TextLineHeight.TextLineHeight24,
    },
  }),
  strong: createTagOverride(TextCustom, {
    appearance: TextAppearance.label200,
    style: {
      color: TextColor.inherit,
      textAlign: TextAlignment.center,
      fontWeight: TextWeight.bold,
      lineHeight: TextLineHeight.TextLineHeight24,
    },
  }),
};
