import { ModelImage } from '../../typings/general';
import { AppConstants } from '../../utils/app-constants';
import { SuccessConfiguration } from '../form-state-views/success-view';

export const getSuccessFormContent = (
  modelImage: ModelImage,
  faHostServerUrl: string,
  successContent?: SuccessConfiguration,
) => {
  const { image: customImage } = successContent || {};
  return {
    ...successContent,
    image: {
      src:
        customImage?.src ||
        modelImage.url ||
        faHostServerUrl + AppConstants.Ngw6Logo960x432 ||
        '',
      alt: customImage?.alt || modelImage?.alt || '',
      srcPlaceholder: AppConstants.PlaceHolder960x432 as string,
      srcErrorImage: faHostServerUrl + AppConstants.Ngw6Logo960x432,
    },
  };
};
