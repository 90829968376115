import { FormFields } from '../typings/inline-image-form';
import { DynamicFormValidationSchema } from './validation-schema';

export interface ConfigFieldsModel {
  firstName: {
    readonly name: string;
    readonly label: string;
    readonly type: string;
    readonly regex: RegExp;
  };
  lastName: {
    readonly name: string;
    readonly label: string;
    readonly type: string;
    readonly regex: RegExp;
  };
  email: {
    readonly name: string;
    readonly label: string;
    readonly optionalLabel: string;
    readonly type: string;
    readonly regex: RegExp;
  };
  phone: {
    readonly name: string;
    readonly label: string;
    readonly optionalLabel: string;
    readonly type: string;
    readonly regex: RegExp;
  };
  preferredContactMethod: {
    readonly name: string;
    readonly label: string;
    readonly fields: RadioModel[];
  };
  comments: {
    readonly name: string;
    readonly label: string;
    readonly placeholder: string;
    readonly maxLenght: number;
  };
  modelName: {
    readonly name: string;
    readonly label: string;
    readonly placeholder: string;
  };
  trim: {
    readonly name: string;
    readonly label: string;
    readonly placeholder: string;
  };
  zip: {
    readonly name: string;
    readonly label: string;
    readonly type: string;
    readonly regex: RegExp;
  };
}

export interface RadioModel {
  readonly value: string;
  readonly label: string;
}

export interface InitialValuesModel {
  readonly firstName?: string;
  readonly lastName?: string;
  readonly email?: string;
  readonly phone?: string;
  readonly preferredContactMethod?: string;
  readonly comments?: string;
  readonly modelName?: string;
  readonly trim?: string;
  readonly zip?: string;
}

export interface BaseFormInfoConfigModel {
  readonly formName: string;
  readonly formLabel: string;
  readonly submitLabel: string;
  readonly legalDisclaimer: string;
}

/** Validation schema :Yup structure */
export const defaultValidationSchemaConfig: DynamicFormValidationSchema = {
  email: {
    invalid: 'Invalid email',
    required: 'Field required',
  },
  phone: {
    invalid: 'Invalid phone number',
    required: 'Field required',
  },
  lastName: {
    invalid: 'Invalid last name',
    required: 'Field required',
    min: 'Last name must be at least 1 characters',
  },
  firstName: {
    invalid: 'Invalid first name',
    required: 'Field required',
    min: 'First name must be at least 1 characters',
  },
  comments: {
    min: 'Message must be at least 1 characters',
    max: 'Message must be at most 1000 characters',
  },
  modelName: {
    required: 'Please select valid model',
  },
  trim: {
    required: 'Please select valid trim',
  },
  zip: {
    invalid: 'Invalid Zipcode',
    required: 'Field required',
  },
};

/** Field structure base on Dynamic Form */
export const defaultFields: ConfigFieldsModel = {
  firstName: {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    regex: /^[A-Za-z\ \'\-]{0,25}$/,
  },
  lastName: {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    regex: /^[A-Za-z\ \'\-]{0,25}$/,
  },
  email: {
    name: 'email',
    label: 'Email',
    optionalLabel: '(Optional)',
    type: 'email',
    regex: /^[\w@.-]*$/,
  },
  phone: {
    name: 'phone',
    label: 'Phone',
    optionalLabel: '(Optional)',
    type: 'text',
    regex: /^[0-9]{0,10}$/,
  },
  preferredContactMethod: {
    name: 'preferredContactMethod',
    label: 'Preferred Contact',
    fields: [
      { value: 'email', label: 'Email' },
      { value: 'phone', label: 'Phone' },
      { value: 'either', label: 'Either' },
    ],
  },
  comments: {
    name: 'comments',
    label: '',
    placeholder: 'Add a message (Optional)',
    maxLenght: 1000,
  },
  modelName: {
    name: 'modelName',
    label: 'Vehicle Model',
    placeholder: 'Select Model',
  },
  trim: {
    name: 'trim',
    label: 'Vehicle Trim',
    placeholder: 'Select Trim',
  },
  zip: {
    name: 'zip',
    label: 'Zip Code',
    type: 'text',
    regex: /^[0-9]{0,5}$/,
  },
};

/** Field structure base on Dynamic Form */
export const DEFAULT_FIELDS_DATA: FormFields[] = [
  {
    name: 'firstName',
    label: 'First Name',
    formType: 'firstName',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    formType: 'lastName',
  },
  {
    name: 'email',
    label: 'Email',
    formType: 'email',
  },
  {
    name: 'phone',
    label: 'Phone',
    formType: 'phone',
  },
  {
    name: 'preferredContactMethod',
    label: 'Preferred Contact',
    formType: 'preferredContactMethod',
  },
  {
    name: 'comments',
    label: '',
    formType: 'comments',
  },
  {
    name: 'modelName',
    label: 'Vehicle Model',
    formType: 'modelName',
  },
  {
    name: 'trim',
    label: 'Vehicle Trim',
    formType: 'trim',
  },
  {
    name: 'zip',
    label: 'Zip Code',
    formType: 'zip',
  },
];
