import { styled, Breakpoints } from '@ngw6/us-components';

export const HeaderWrapper = styled.div`
  width: 80%;
  padding-bottom: 18px;
  @media (min-width: ${Breakpoints.b560}px) {
    width: 70%;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    width: 100%;
  }
`;

export const FailedTextWrapper = styled.div`
  width: 90%;
  padding-bottom: 64px;
  @media (min-width: ${Breakpoints.b560}px) {
    width: 50%;
    padding-bottom: 74px;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    width: 30%;
  }
`;
