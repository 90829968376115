import { styled, Breakpoints } from '@ngw6/us-components';

export const StyledProcessViewContainer: any = styled.div``;

interface ModalFormWrapperProps {
  withTopPadding: boolean;
}
export const ModalFormWrapper: any = styled.div<ModalFormWrapperProps>`
  ${({ withTopPadding }: ModalFormWrapperProps) => `
    width: 100%;
    display: flex;
    padding: 52px 0 100px 0;
    padding: ${withTopPadding ? ' 52px 0 100px 0' : '0'};
    overflow: hidden;
    @media (min-width: ${Breakpoints.b960}px) {
      padding: ${withTopPadding ? '70px 150px' : '0px 150px'}
    }`}
`;
export const StyledModalFormWrapper: any = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const ModalFormContainer: any = styled.div``;

interface StyledSuccessViewProps {
  showButton?: boolean;
}
export const StyledSuccessView: any = styled.div<StyledSuccessViewProps>`
  ${({ showButton }: StyledSuccessViewProps) =>
    showButton &&
    `button {
           display: none;
         }`}
`;
