import {
  InputAppearance,
  HandleDropdownEvent,
  styled,
  css,
} from '@ngw6/us-components';

interface StyledDropdownProps {
  readonly appearance?: InputAppearance;
  readonly disabled?: boolean;
  readonly value?: string;
  readonly errorText?: string;
  readonly onChange?: HandleDropdownEvent;
  readonly ref?: any;
}

interface StyledLabelProps {
  readonly disabled?: boolean;
}
// the icon needs to be moved down by the size of the wrapper's top padding plus the size of the select's top padding
export const StyledIcon: any = styled.span<any>`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 2px;
  pointer-events: none;
  .animation svg {
    transition: transform 0.5s ease;
    transform: rotate(0deg);
  }
  .animation.active svg {
    transform: rotate(180deg);
  }
`;

export const StyledLabelText: any = styled.span`
  ${(props: any) => css`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-size: ${props.theme.fonts?.copy[200].fontSize};
    color: ${props.theme.interaction.recipe450.default};
    pointer-events: none;
    padding-left: 5px;
  `}
`;

export const StyledDropdown: any = styled.div<StyledDropdownProps>`
  ${(props: any) => css`
    appearance: none;
    box-shadow: none;
    display: flex;
    flex-flow: column;
    width: 100%;
    border: 0;
    color: ${props.theme.interaction.recipe450.default};
    background: transparent;
    outline: none;
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
    &:disabled {
      color: ${props.theme.interaction.recipe450.disabled};
      border-color: ${props.theme.interaction.recipe150.disabled};

      ~ ${StyledLabelText}, ~ ${StyledIcon} {
        color: ${props.theme.interaction.recipe450.disabled};
      }
    }
  `}
`;

export const StyledDropdownBoxOption: any = styled.div`
  ${(props: any) => css`
    padding: 5px 10px;
    border-bottom: 1px solid;
    border-radius: 0;
    color: ${props.theme.interaction.recipe450.default};
    border-color: ${props.theme.interaction.recipe150.default};
    &:hover {
      color: ${props.theme.interaction.recipe450.active};
      background-color: rgb(223, 228, 232);
    }
  `}
`;
export const DropdownInput: any = styled.div<StyledDropdownProps>`
  ${(props: any) => css`
    border-bottom: 1px solid;
    border-radius: 0;
    border-color: ${props.appearance === InputAppearance.Error &&
    props.errorText
      ? props.theme.interaction.recipe360.default
      : props.theme.interaction.recipe150.default};
  `}
`;

export const StyledErrorText: any = styled.span`
  ${(props: any) => css`
    display: block;
    padding-top: 2px;
    color: ${props.theme.interaction.recipe360.default};
    font-size: ${props.theme.fonts?.copy[200].fontSize};
  `}
`;

export const StyledDropdownBoxWrapper: any = styled.div`
  transform: translateY(100%);
  height: inherit;
  overflow-y: visible;
  z-index: 2;
`;
export const StyledDropdownBox: any = styled.div`
  box-sizing: border-box;
  position: absolute;
  list-style-type: none;
  margin-top: 0;
  padding-left: 0;
  background: #fff;
  border-top: none;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 10px #b8c5ca;
`;

export const StyledLabel: any = styled.div<StyledLabelProps>`
  ${(props: any) => css`
    position: relative;
    padding-top: 2px;
    padding-left: 5px;
    color: ${props.theme.interaction.recipe450.default};
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
    :hover {
      ${StyledIcon} {
        color: ${props.theme.interaction.recipe450.hover};
      }

      ${StyledLabelText} {
        color: ${props.theme.interaction.recipe450.hover};
      }

      ${StyledDropdown}:not(:disabled) {
        color: ${props.theme.interaction.recipe450.hover};
      }
    }
  `}
`;
