import _ from 'lodash';
import {
  FeatureAppTrackingManager,
  getDefaultTrackingEvent,
} from '../services/use-tracking-manager';
import { FormConfiguration } from '../typings/configuration';
import { ModelData } from '../typings/general';

interface FormErrorFields {
  formFields?: string[];
  errorFields: string[];
  errorField?: string;
  errorMessage?: string;
}

const fieldsErrorCodes = {
  'Invalid email': 'FE01',
  'Field required': 'FE02',
  'Invalid phone number': 'FE03',
  'Invalid last name': 'FE04',
  'Last name must be at least 1 characters': 'FE05',
  'Invalid first name': 'FE06',
  'First name must be at least 1 characters': 'FE07',
  'Message must be at least 1 characters': 'FE08',
  'Message must be at most 1000 characters': 'FE09',
  'Please select valid model': 'FE10',
  'Please select valid trim': 'FE11',
  'Invalid Zipcode': 'FE12',
  '': 'FE00',
};

const formTypeMap = {
  'contact': 'contact',
  'quote': 'quote',
  'registration': 'registration',
  'newLetter': 'newsletter',
  '': 'otherform',
};

/**
 * handle zipcode change tracking
 * @param name
 * @param trackingManager
 * @param store
 */
export const onVWBasicLayerloadEvent = (
  trackingManager?: FeatureAppTrackingManager,
  isInlineForm?: boolean,
  model?: FormConfiguration,
  modelData?: ModelData,
) => {
  if (model?.disableTracking) return;
  let customEvent = getDefaultTrackingEvent();

  if (!isInlineForm) {
    customEvent.eventInfo = {
      ...customEvent.eventInfo,
      contentId: 'Form Layer',
    };
  } else {
    customEvent.eventInfo = {
      ...customEvent.eventInfo,
      contentId: `${model?.formName} form`,
    };
  }
  customEvent.form = getBasicFormData(
    model?.formName,
    customEvent.form,
    true,
    model?.isNewsLetter,
    model?.formTrackingType,
    true,
  );
  if (model?.formTrackingId) {
    customEvent.environment = {
      ...customEvent.environment,
      featureAppId: model?.formTrackingId,
    };
  }
  if (modelData || model?.modelName) {
    customEvent.configuration = {
      ...customEvent.configuration,
      CarlineName: modelData?.name || model?.modelName,
      CarlineId: modelData?.carlineId,
    };
  }
  trackingManager &&
    trackingManager.trackFormsGlobalEvent(
      isInlineForm ? 'VWBasic_Form_Load' : 'VWBasic_FormLayer_Load',
      undefined,
      customEvent,
    );
};

/**
 * Vehicle button form tag
 * @param offer
 * @param name
 * @param trackingManager
 * @param store
 */
export const onHandleBasicFormButtonLink = (
  trackingManager: FeatureAppTrackingManager,
  model?: FormConfiguration,
  modelData?: ModelData,
  formName?: string,
) => {
  if (model?.disableTracking) return;
  let customEvent = getDefaultTrackingEvent();

  if (modelData || model?.modelName)
    customEvent.configuration = {
      ...customEvent.configuration,
      CarlineName: modelData?.name || model?.modelName,
      CarlineId: modelData?.carlineId,
    };
  customEvent.eventInfo = getContentInfo(
    false,
    formName,
    customEvent.eventInfo,
  );
  customEvent.link = {
    ...customEvent.link,
    url: `open ${model?.CTAlabel} form modal`,
    name: model?.CTAlabel,
    newTab: false,
  };
  customEvent.user = {
    ...customEvent.user,
    loginStatus: false,
  };

  if (model?.formTrackingId) {
    customEvent.environment = {
      ...customEvent.environment,
      featureAppId: model?.formTrackingId,
    };
  }
  trackingManager.trackFormsGlobalEvent(
    'VWBasic_Link_Click',
    model,
    customEvent,
  );
};

/**
 * Handle form status tracking
 * @param type
 * @param trackingManager
 * @param offer
 * @param formValues
 * @param formId
 * @param error
 */
export const onHandleFormSubmitTracking = (
  type: 'load' | 'success' | 'error',
  isInline?: boolean,
  formName?: string,
  trackingManager?: FeatureAppTrackingManager,
  model?: FormConfiguration,
  formValues?: any,
  formId?: string,
  error?: FormErrorFields,
  errorMessage?: { message?: string; code?: string },
  modelData?: ModelData,
) => {
  if (model?.disableTracking) return;
  let customEvent = getDefaultTrackingEvent();
  const trackingType = {
    load: 'VWBasic_FormCTA_Click',
    success: 'VWBasic_FormSubmissionSuccessMessage_Load',
    error: 'VWBasic_FormErrorMessage_Load',
  };
  const event = trackingType[type];

  if (type === 'load')
    customEvent.link = {
      ...customEvent.link,
      url: 'Submit form',
      name: `Submit`,
    };
  customEvent.form = getBasicFormData(
    formName,
    customEvent.form,
    false,
    model?.isNewsLetter,
    model?.formTrackingType,
    type !== 'error',
  );
  if (type !== 'error') {
    customEvent.form = {
      ...customEvent.form,
      PrefContactChannels: formValues?.preferredContactMethod,
      LeadID: createSuccessLeadId(
        formId,
        model?.successRequestCountryCode,
        model?.successRequestLeadId,
      ),
      FormStart: false,
      MarketingConsent: undefined,
    };
    if (modelData || model?.modelName || formValues)
      customEvent.configuration = {
        ...customEvent.configuration,
        CarlineName:
          modelData?.name || model?.modelName || formValues?.modelName,
        CarlineId: modelData?.carlineId,
        SalesgroupName: formValues
          ? formValues?.modelName + ' ' + formValues?.trim
          : undefined,
      };
  }
  if (type === 'success') {
    customEvent.form = getFormFields(formValues, customEvent.form);
  }
  if (error) {
    customEvent.form = getErrorFields(error, customEvent.form);
  }
  if (errorMessage) {
    customEvent.error = {
      ...customEvent.error,
      code: errorMessage.code || fieldsErrorCodes[errorMessage.message || ''],
      message: errorMessage.message,
    };
  }
  customEvent.eventInfo = getContentInfo(
    isInline,
    formName,
    customEvent.eventInfo,
  );
  if (model?.formTrackingId) {
    customEvent.environment = {
      ...customEvent.environment,
      featureAppId: model?.formTrackingId,
    };
  }
  trackingManager &&
    trackingManager.trackFormsGlobalEvent(event, model, customEvent);
};

/**
 * Handle form load
 * @param type
 * @param formType
 * @param formName
 * @param offer
 * @param trackingManager
 */
export const onHandleLoadTracking = (
  type: 'load' | 'close',
  trackingManager?: FeatureAppTrackingManager,
  model?: FormConfiguration,
  modelData?: ModelData,
) => {
  if (model?.disableTracking) return;
  const event =
    type === 'load' ? 'VWBasic_FormLayer_Load' : 'VWBasic_LayerClose_Click';
  let customEvent = getDefaultTrackingEvent();
  if (type !== 'close') {
    customEvent.form = getBasicFormData(
      model?.formName,
      customEvent.form,
      type === 'load',
      model?.isNewsLetter,
      model?.formTrackingType,
      true,
    );
  } else {
    customEvent.link = {
      ...customEvent.link,
      url: 'close modal form',
      name: 'close form',
    };
  }
  customEvent.eventInfo = {
    ...customEvent.eventInfo,
    contentId: `${model?.formName} modal form`,
  };
  if (modelData || model?.modelName)
    customEvent.configuration = {
      ...customEvent.configuration,
      CarlineName: modelData?.name || model?.modelName,
      CarlineId: modelData?.carlineId,
    };
  if (model?.formTrackingId) {
    customEvent.environment = {
      ...customEvent.environment,
      featureAppId: model?.formTrackingId,
    };
  }
  trackingManager &&
    trackingManager.trackFormsGlobalEvent(event, model, customEvent);
};

function getFormType(formName?: string, isNewsLetter?: boolean) {
  if (isNewsLetter) return formTypeMap['newLetter'];
  if (!formName) return formTypeMap[''];
  const formType = formName.toLocaleLowerCase();
  if (formType.includes('contact')) return formTypeMap['contact'];
  if (formType.includes('registration')) return formTypeMap['registration'];
  if (formType.includes('sign')) return formTypeMap['registration'];
  if (formType.includes('quote')) return formTypeMap['quote'];
  return formTypeMap[''];
}

function getBasicFormData(
  formName?: string,
  eventObject?: any,
  isFormStart?: boolean,
  isNewsLetter?: boolean,
  formType?: string,
  hasNewType?: boolean,
) {
  return {
    ...eventObject,
    FormType: formType || getFormType(formName || '', isNewsLetter),
    FormName: formName,
    FormStart: isFormStart ? true : undefined,
    FormVersion: '1',
    NewsletterType: hasNewType
      ? isNewsLetter
        ? ['news']
        : undefined
      : undefined,
  };
}

function getFormFields(formFieldsEvent: any, event: any) {
  const formFieldsSchema = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phone: 'phone',
    preferredContactMethod: 'email',
    comments: 'comments',
    modelName: 'modelName',
    trim: 'trim',
    zip: 'zip',
  };
  const cleanedFields = Object.keys(formFieldsEvent).filter(
    formField => formFieldsSchema[formField],
  );
  const formFields: any[] = [];
  cleanedFields?.map((key: string) => {
    formFields.push({ formfieldname: key });
  });
  return { ...event, FormFields: formFields };
}

function getErrorFields(error: FormErrorFields, event: any) {
  const errorFields: any[] = [];
  const formFields: any[] = [];
  error.errorFields.map((key: string) => {
    errorFields.push({ formfieldname: key });
  });
  error.formFields?.map((key: string) => {
    formFields.push({ formfieldname: key });
  });
  return {
    ...event,
    ErrorFormFields: errorFields,
    FormFields: formFields,
    FormFieldName: error.errorField,
  };
}

const getContentInfo = (isInline?: boolean, formName?: string, event?: any) => {
  if (!isInline) {
    return { ...event, contentId: 'Form Layer' };
  } else {
    return {
      ...event,
      contentId: `${formName} form`,
    };
  }
};

/**
 * Create Success request response according with configured data
 * @param formId request form response
 * @param successRequestCountryCode : Configured Tracking Variable
 * @param successRequestLeadId : Configured Tracking Variable
 * @returns new request Id | current request response | undefined
 */
function createSuccessLeadId(
  formId?: string | undefined,
  successRequestCountryCode?: string | undefined,
  successRequestLeadId?: string | undefined,
): string | undefined {
  if (!formId) return undefined;
  let newRequestId = formId;
  if (successRequestLeadId) {
    newRequestId = `${successRequestLeadId}-${newRequestId}`;
  }
  if (successRequestCountryCode) {
    newRequestId = `${successRequestCountryCode}-${newRequestId}`;
  }
  return newRequestId;
}
