import React, { ReactElement } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useFeatureServices } from '../../services/use-feature-services';
import { useModelImageMap } from '../../services/use-model-image-map';
import {
  defaultFieldsConfig,
  defaultFormConfiguration,
} from '../../config/localConfig';
import _ from 'lodash';
import { getDCCFormValidationSchema } from '../../config/validation-schema';
import { FormState, ModelImage, stringMap } from '../../typings/general';
import { defaultValidationSchemaConfig } from '../../config/formFields';
import { getSubmitHandlerFunction } from './submit-function';
import { getStringifiedServiceConfigsServiceConfig } from '../../services/get-stringified-service-configs-service-config';
import { useTrackingManager } from '../../services/use-tracking-manager';
import { useModelData } from '../../services/use-model-data';
import { FieldsConfiguration } from '../../typings/configuration';
import { useFeatureAppMainConfig } from '../../services/use-feature-app-main-config';
import { useFeatureAppConfig } from '../../services/use-feature-app-config';

interface FormikCustomProps {
  setFormState: (state: FormState) => void;
  setModelImage: (modelImage: ModelImage) => void;
  isInline?: boolean;
  children: ReactElement;
  fieldsContentConfig?: FieldsConfiguration;
}
const FormikCustom: React.FC<FormikCustomProps> = props => {
  const {
    children,
    setFormState,
    setModelImage,
    isInline,
    fieldsContentConfig,
  } = props;
  let { faServicesUrl, mockIds, mocksBaseUrl } = useFeatureAppConfig();
  let { fieldsConfiguration, formConfiguration } = useFeatureAppMainConfig();
  const {
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
    'zip-manager': zipManager,
  } = useFeatureServices();

  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest,
  );
  const modelImageMap = useModelImageMap();
  if (fieldsContentConfig) fieldsConfiguration = fieldsContentConfig;

  if (!fieldsConfiguration) fieldsConfiguration = defaultFieldsConfig;
  const isZipCodeForm = !!fieldsConfiguration['zip'];
  fieldsConfiguration = isZipCodeForm
    ? { ...fieldsConfiguration, zip: zipManager.getZipCookie() }
    : fieldsConfiguration;

  const validationSchema = getDCCFormValidationSchema(
    defaultValidationSchemaConfig,
    fieldsConfiguration,
  );

  // Doesn't validate zipcode field
  if (formConfiguration.implicitZipCode) {
    fieldsConfiguration = {
      ...fieldsConfiguration,
      zip: zipManager.getZipCookie(),
    };
  }
  const modelData = useModelData();

  if (modelData?.name && typeof fieldsConfiguration.modelName !== 'undefined') {
    fieldsConfiguration = {
      ...fieldsConfiguration,
      modelName: modelData?.name || '',
    };
  }

  const resetFormState = (
    state: FormState,
    values: stringMap,
    props: FormikHelpers<stringMap>,
  ) => {
    if (state === 'submitting') return setFormState(state);
    if (values['modelName']) {
      let image: ModelImage;
      const modelImage = modelImageMap[values['modelName']];
      image =
        modelImage.trims && modelImage.trims[values['trim']].url
          ? modelImage.trims[values['trim']]
          : modelImage;
      setModelImage(image);
    }
    if (state === 'success') props.resetForm();
    setFormState(state);
  };

  const trackingManager = useTrackingManager();
  const submitHandler = getSubmitHandlerFunction(
    resetFormState,
    faServicesUrl,
    formConfiguration.requestType,
    formConfiguration.paramsType,
    serviceConfigsServiceConfig,
    mockIds,
    mocksBaseUrl,
    modelImageMap,
    formConfiguration,
    trackingManager,
    modelData,
    isInline,
  );

  const onHandleValidate = async (values: stringMap) => {
    return validationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        return {};
      })
      .catch(async error => {
        let errors: any = {};
        error.inner.map((value: any) => {
          if (
            (!value.params.value && value.type === 'required') ||
            (value.params.value && value.type !== 'required')
          ) {
            errors[value.path] = value.message;
          }
        });
        return errors;
      });
  };

  return (
    <Formik
      initialValues={fieldsConfiguration}
      initialStatus={false}
      onSubmit={submitHandler}
      validateOnMount={true}
      validate={onHandleValidate}
      isInitialValid={false}
      enableReinitialize
    >
      {children}
    </Formik>
  );
};

export default FormikCustom;
