import {
  Button,
  ButtonAppearance,
  CustomImage,
  DisableFocusWrapper,
  TextAlignment,
  TextAppearance,
  Richtext,
  CustomImageProps,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextWeight,
} from '@ngw6/us-components';
import React from 'react';
import { useFeatureAppConfig } from '../../../services/use-feature-app-config';
import { ContentWrapper, StateWrapper } from '../styled';
import {
  descriptionSuccessTextStyle,
  getDefaultContentConfig,
} from './helpers';
import { HeaderWrapper, ImageWrapper, TextWrapper } from './styled';

export interface SuccessConfiguration {
  headerLabel?: string;
  description?: string;
  buttonCtaLabel?: string;
  image: CustomImageProps;
}
interface SuccessViewProps {
  okHandler: () => void;
  inline?: boolean;
  contentConfiguration?: SuccessConfiguration;
}

export const SuccessView = ({
  okHandler,
  inline,
  contentConfiguration,
}: SuccessViewProps) => {
  const { faHostServerUrl = '' } = useFeatureAppConfig();
  const defaultContentConfig = getDefaultContentConfig(faHostServerUrl);
  const successContent: SuccessConfiguration =
    (contentConfiguration && {
      ...defaultContentConfig,
      ...contentConfiguration,
    }) ||
    defaultContentConfig;
  return (
    <StateWrapper inline={inline}>
      <ContentWrapper inline={inline}>
        <HeaderWrapper>
          <TextCustom
            appearance={TextAppearance.headline300}
            style={{
              fontSize: TextSize.TextSize28,
              lineHeight: TextLineHeight.TextLineHeight32,
              fontWeight: TextWeight.bold,
              textAlign: TextAlignment.center,
            }}
          >
            {successContent?.headerLabel || ``}
          </TextCustom>
        </HeaderWrapper>
        <TextWrapper>
          <Richtext
            markdown={successContent?.description || ''}
            overrides={descriptionSuccessTextStyle}
            dangerouslyParseRawHTML
          />
        </TextWrapper>
        <ImageWrapper>
          <CustomImage {...successContent.image} />
        </ImageWrapper>
        <DisableFocusWrapper>
          <Button appearance={ButtonAppearance.Primary} onClick={okHandler}>
            {successContent.buttonCtaLabel}
          </Button>
        </DisableFocusWrapper>
      </ContentWrapper>
    </StateWrapper>
  );
};
