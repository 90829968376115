import * as s from 'superstruct';
import { DEFAULT_MODE_CONTENT } from '../config/default_content';
import {
  FormContentSchema,
  FormSchema
} from '../typings/inline-image-form';
import { useFeatureServices } from './use-feature-services';

export type FeatureAppContent = s.Infer<typeof FormContentSchema>;

export function useFeatureAppContent(): FormSchema {
  const { 'fa-content-service': contentService } = useFeatureServices();
  const content = contentService?.content;
  const [err] = s.validate(content, FormContentSchema);
  if (err) {
    console.log(
      'Schema contains invalid data:',
      err.message,
      'schema',
      content,
    );
  }
  return s.is(content, FormContentSchema)
    ? (content as FormSchema)
    : DEFAULT_MODE_CONTENT;
}
