import {
  TextAppearance,
  TextColor,
  TextCustom,
  TextCustomProps,
  TextLineHeight,
  TextSize,
  TextTag,
} from '@ngw6/us-components';
import React from 'react';
import { StyledImageDisclaimerWrapper } from './styles';

export interface ImageDisclaimerProps {
  description?: string;
}
const ImageDisclaimer: React.FC<ImageDisclaimerProps> = props => {
  const { description } = props;
  const disclaimerStyle: TextCustomProps = {
    appearance: TextAppearance.copy100,
    tag: TextTag.span,
    style: {
      color: TextColor.inherit,
      fontSize: TextSize.TextSize14,
      lineHeight: TextLineHeight.TextLineHeight24,
    },
  };
  if (!description) return <></>;
  return (
    <StyledImageDisclaimerWrapper className="image-disclaimer-container">
      <TextCustom {...disclaimerStyle}>{description}</TextCustom>
    </StyledImageDisclaimerWrapper>
  );
};

export default ImageDisclaimer;
