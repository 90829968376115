import { AppConstants } from '../utils/app-constants';

export const validateZip = async (zipCode?: string): Promise<boolean> => {
  const zipCodeRegExp: RegExp = /^[0-9]{5}$/;
  if (!zipCode || !zipCodeRegExp.test(zipCode)) return false;
  const response = await fetch(
    AppConstants.GoogleApiGeoCodeUrl.replace('{postalCode}', zipCode),
    { method: 'get' },
  );
  if (response.ok) {
    const data = await response.json();
    if (data && data.results && data.results.length) return true;
  } else {
    console.log('ERROR:: Zip validation, status: ', response.status);
  }
  return false;
};
