import _, { isFunction } from 'lodash';
import isBrowser from 'is-browser';
import {
  FormState,
  stringMap,
  ModelImageMap,
  ModelData,
} from '../../typings/general';
import { submitForm } from '../../fetch-data/submit-data/submit-form';
import { FormResponseState } from '../../fetch-data/submit-data/helpers/response-states';
import { FormikHelpers } from 'formik';
import { onHandleFormSubmitTracking } from '../../utils/tracking-helpers';
import { FeatureAppTrackingManager } from '../../services/use-tracking-manager';
import { FormConfiguration } from '../../typings/configuration';

type SubmitFunction = (
  values: stringMap,
  props: FormikHelpers<stringMap>,
) => void;

// Tagging source from window
declare global {
  interface Window {
    bt_cookie: any;
  }
}

export const getSubmitHandlerFunction = (
  setFormState: (
    state: FormState,
    values: stringMap,
    props: FormikHelpers<stringMap>,
  ) => void,
  faServicesUrl: string,
  requestType: string,
  paramsType: string,
  serviceConfigsServiceConfig?: string,
  mockIds?: string,
  mocksBaseUrl?: string,
  imageMap?: ModelImageMap,
  formConfiguration?: FormConfiguration,
  trackingManager?: FeatureAppTrackingManager,
  modelData?: ModelData,
  isInline?: boolean,
): SubmitFunction => async (values: any, props: FormikHelpers<stringMap>) => {
  setFormState('submitting', values, props);
  let processedValues = addTaggingCode(values);
  processedValues = sanitazeValues(processedValues, imageMap);
  onHandleFormSubmitTracking(
    'load',
    isInline,
    formConfiguration?.formName,
    trackingManager,
    formConfiguration,
    processedValues,
    undefined,
    undefined,
    undefined,
    modelData,
  );

  // Submit Form
  submitForm({
    faServicesUrl,
    serviceConfigsServiceConfig,
    mockIds,
    mocksBaseUrl,
    userParams: processedValues,
    requestType,
    paramsType,
  })
    .then((result: FormResponseState) => {
      const state = !result.error && !result.invalid ? 'success' : 'failed';
      setFormState(state, values, props);
      result.data &&
        result.data.leadId &&
        onHandleFormSubmitTracking(
          'success',
          isInline,
          formConfiguration?.formName,
          trackingManager,
          formConfiguration,
          processedValues,
          result.data.leadId,
          undefined,
          undefined,
          modelData,
        );
    })
    .catch((error: any) => {
      console.log(error);
      setFormState('failed', values, props);
    });
};

const addTaggingCode = (values: any): stringMap => {
  let taggedValues = _.cloneDeep(values);
  if (values['tag-BrightTagID']) {
    taggedValues['tag-BrightTagID'] = isBrowser
      ? isFunction(window.bt_cookie)
        ? window.bt_cookie('BTid')
        : ''
      : '';
  }
  if (values['tag-CampaignID']) {
    taggedValues['tag-CampaignID'] = isBrowser
      ? isFunction(window.bt_cookie)
        ? window.bt_cookie('s_campaign')
        : ''
      : '';
  }
  if (values['tag-OmnitureID']) {
    taggedValues['tag-OmnitureID'] = isBrowser
      ? isFunction(window.bt_cookie)
        ? window.bt_cookie('s_vi')
        : ''
      : '';
  }

  return taggedValues;
};

/**
 * Delete data if it is unnecesary for the type or request
 * @param form
 * @param isTier2
 */
const sanitazeValues = (form: any, imageMap?: ModelImageMap) => {
  let cleanedForm = form;

  // Clean phone number or property
  if (!form.phone) {
    cleanedForm = _.omit(cleanedForm, 'phone');
  } else {
    cleanedForm.phone = cleanedForm.phone.replace(/[^\d]/g, '');
  }

  // Clean mail if doens't exist  property
  if (!form.mail) {
    cleanedForm = _.omit(cleanedForm, 'mail');
  }

  // Clean For Service request
  if (typeof form.year === 'undefined') {
    cleanedForm = _.omit(cleanedForm, 'year');
  } else {
    const year =
      form.modelName && imageMap ? imageMap[form.modelName].modelYear : '';
    cleanedForm = { ...cleanedForm, year };
  }
  if (!form.trim) cleanedForm = _.omit(cleanedForm, 'trim');

  // Add
  if (typeof form.comments !== 'undefined' && typeof form.zip !== 'undefined')
    cleanedForm = {
      ...cleanedForm,
      comments: getFormatedCommentsText(cleanedForm.zip, cleanedForm.comments),
    };

  return cleanedForm;
};

/**
 * Added to current message extra needed data
 * @param zipCode
 */
const getFormatedCommentsText = (zipCode: string, comments: string): string => {
  return `${comments} mmz:${zipCode}`;
};
