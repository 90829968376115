import * as Yup from 'yup';
import _ from 'lodash';
import { FieldsConfiguration } from '../typings/configuration';
import { validateZip } from '../fetch-data/validate-zip';

/**
 * Validate phone number format
 */
const phoneRegExp = /\([0-9]{3}\)\s[0-9]{3}\-[0-9]{4}/;
const zipCodeRegExp = /^[0-9]{5}$/;

export interface DynamicFormValidationSchema {
  email: {
    invalid: string;
    required: string;
  };
  phone: {
    invalid: string;
    required: string;
  };
  lastName: {
    invalid: string;
    required: string;
    min: string;
  };
  firstName: {
    invalid: string;
    required: string;
    min: string;
  };
  comments: {
    min: string;
    max: string;
  };
  modelName: {
    required: string;
  };
  trim: {
    required: string;
  };
  zip: {
    invalid: string;
    required: string;
  };
}

export const getDCCFormValidationSchema = (
  config: DynamicFormValidationSchema,
  fields: FieldsConfiguration,
): Yup.ObjectSchema => {
  let baseSchema: any = {};
  typeof fields['email'] !== 'undefined' &&
    (baseSchema.email = Yup.string()
      .email(config.email.invalid)
      .when('preferredContactMethod', {
        is: (value: string) => {
          if (typeof fields['preferredContactMethod'] !== 'undefined')
            return value === 'email' || value === 'either';
          else {
            return true;
          }
        },
        then: Yup.string().required(config.email.required),
      }));

  typeof fields['firstName'] !== 'undefined' &&
    (baseSchema['firstName'] = Yup.string()
      .min(1, config.firstName.min)
      .required(config.firstName.required));

  typeof fields['lastName'] !== 'undefined' &&
    (baseSchema.lastName = Yup.string()
      .min(1, config.lastName.min)
      .required(config.lastName.required));

  typeof fields['phone'] !== 'undefined' &&
    (baseSchema.phone = Yup.string()
      .matches(phoneRegExp, 'Invalid phone')
      .when('preferredContactMethod', {
        is: (value: string) => {
          if (typeof fields['preferredContactMethod'] !== 'undefined')
            return value === 'phone' || value === 'either';
          else {
            return true;
          }
        },
        then: Yup.string().required(config.phone.required),
      }));

  typeof fields['comments'] !== 'undefined' &&
    (baseSchema.comments = Yup.string()
      .min(1, config.comments.min)
      .max(1000, config.comments.max));

  typeof fields['modelName'] !== 'undefined' &&
    (baseSchema.modelName = Yup.string().required(config.modelName.required));

  typeof fields['trim'] !== 'undefined' &&
    (baseSchema.trim = Yup.string().required(config.trim.required));

  typeof fields['zip'] !== 'undefined' &&
    (baseSchema.zip = Yup.string()
      .required(config.zip.required)
      .matches(zipCodeRegExp, config.zip.invalid)
      .test(
        'is-zipcode-valid',
        config.zip.invalid,
        async value => await validateZip(value),
      ));
  return Yup.object().shape(baseSchema);
};
