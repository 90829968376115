import { styled } from "@ngw6/us-components";


export const StyledModalContainer: any = styled.div`
         position: fixed;
         z-index: 2000;
       `;

       export const StyledProcessViewContainer: any = styled.div`
         .Processing-class {
           position: fixed;
           z-index: 2000;
         }
       `;
