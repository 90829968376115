import {
  FieldsConfiguration,
  FormConfiguration,
} from '../typings/configuration';

export const defaultFieldsConfig: FieldsConfiguration = {
  'brand': 'VW',
  'sourceType': 'vw',
  'subType': ' vw_special offers',
  'tag-BrightTagID': '',
  'tag-CampaignID': '',
  'tag-OmnitureID': '',
  'formType': 'lead',
  'formName': '',
  'requestType': '',
  'firstName': '',
  'lastName': '',
  'email': '',
  'phone': '',
  'preferredContactMethod': 'email',
  'comments': '',
  'modelName': '',
  'trim': '',
  'zip': '',
};

export const defaultFormConfiguration: FormConfiguration = {
  formName: 'Contact Dealer',
  title: '**Check your qualification**',
  description:
    'Contact your local dealer to see if you qualify for the Volkswagen Military & First Responder Bonus.',
  CTAlabel: 'Contact your local dealer',
  background: 'linear-gradient(to bottom, #00437a -50%, #001e50 50%)',
  legalDisclaimer:
    'By clicking "SUBMIT," you accept our [Terms of Service](/en/terms.html) and [Privacy Statement](/en/privacy.html), you request that Volkswagen provide your personal information to our authorized dealers so that the dealers can contact you with marketing information, and you authorize Volkswagen and its dealers to contact you with marketing information, including by calling or texting you at the phone number(s) you\'ve provided. You understand these calls or texts may use computer-assisted dialing and/or prerecorded messages. This authorization is not required to complete the purchase or lease of any Volkswagen product. See Volkswagen of America\'s [Terms of Service](/en/terms.html) and [Privacy Statement](/en/privacy.html).',
  submitCTA: 'Submit',
  requestType: 'leads',
  paramsType: 'queryParamsJson',
  formStructure:
    '"model" "firstName" "lastName" "email" "phone" "contactMethod" "zip" "comments"',
  structureColumns: 1,
  hideModelDropdown: false,
  implicitZipCode: false,
};
