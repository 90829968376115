import { styled } from '@ngw6/us-components';
import React from 'react';

export interface DropdownOptionProps {
  value?: string;
  label?: string;
}

const StyledOption = styled.div<DropdownOptionProps>``;

export const DropdownOption: React.FC<DropdownOptionProps> = props => {
  const { value, label } = props;
  return <StyledOption value={value}>{label}</StyledOption>;
};
